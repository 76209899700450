import moment from 'moment'
export const validateUppercase = (value, constraint) => {

    if(value.toUpperCase() === value) return { status: true }

    return { status: false, key: "validation.uppercase" }

}
export const validateClearingNumber = (value, constraint) => {
    if(!value) return {status: true}
    let parts = constraint ? constraint.split("~") : []
    let rule = parts.length > 1 ? parts[1] : null
    let message = parts[0]
    if(rule){
        let regex = new RegExp(rule.replace(/#/g,'|'))
        if(regex.exec(value)){
            return {status: true}
        }
    }

    return { status: false, key: `validation.clearingnumber.${message}` }    
}
export const validateAccountNumber = (value, constraint) => {
    if(!value) return {status: true}
    let parts = constraint ? constraint.split("~") : []
    let rule = parts.length > 1 ? parts[1] : null
    let message = parts[0]
    if(rule){
        let regex = new RegExp(rule)
        if(regex.exec(value)){
            return {status: true}
        }
    }
    return { status: false, key: `validation.accountnumber.${message}` }    
}
export const validateAge = (value, constraint) => {
    if(!value) return {status: true}
    
    let dob = value.substring(0,8)
	let age = moment().diff(moment(dob, 'YYYYMMDD', true), 'years')
    if(age<65 && value.length==13){
        return {status: false, key: `validation.elder`}
    }else{
        return {status: true}
    }   
}