<template>
    <div class="stepper">
        <div class="container pt-3 pb-0">
            <div class="row">
                <div class="col col-12 col-md-4 mt-1 d-none d-md-flex">
                    <h5 v-if="steps[step - 1].title">{{ steps[step - 1].title }}</h5>
                </div>
                <div :class="['col', 'col-6', 'col-md-4', 'text-center', 'steps']">
                    <ul>
                        <li v-for="(n, index) in steps" :key="index" @click="handleStepClick(index + 1)" :class="[{'complete': step > index + 1}, {'current': step == index + 1}]"><span>{{ index + 1 }}</span></li>
                    </ul>
                </div>
                <div :class="['col', 'col-6', 'col-md-4', 'text-right', 'btn-col']">
                    <button @click="handleBtnNextClick" type="button" class="btn btn-rounded btn-primary next-btn" v-if="!steps[step - 1].final">
                        <div v-if="steps[step - 1].loading" class="spinner-border spinner-border-sm">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span :style="steps[step - 1].loading ? 'opacity: 0': ''">{{ steps[step - 1].button || __('stepper.continue') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ve-custom-stepper",
    props: {
        steps: {
            type: Array,
            default: 1
        },
        step: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        handleBtnNextClick() {
            this.$emit('btn-next-click', true)
        },
        handleStepClick(n) {
            if(this.step > n)
                this.$emit('step-click', n)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
