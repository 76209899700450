<template>
    <div>
        <label :class="required ? 'required':''" v-html="label"></label>
        <button v-if="tip" type="button" data-toggle="tooltip"
            :class="tip['tip-position']=='left' ? 'tooltip-left' : 'tooltip-right'"  
            :data-offset="tip.offset||0"
            :data-placement="tip['text-placement']||'top'"
            :data-trigger="tip.trigger||'hover focus'"
            :data-html="tip.html ? 'true' : 'false'"
            :title="__(tip.text||tip.html)||tip.text||tip.html"></button>
    </div>
</template>

<script>
/**
 * Documentation: https://getbootstrap.com/docs/4.1/components/tooltips/
 */

export default {
    name: "ve-custom-label",
    props: {
        required: {
            type: Boolean,
            default: false
        },
        label: [String],
        tip: {
            type: Object,
            default: undefined
        }
    },
    mounted() {
        if(this.tip) {
            /** 
             * Removing sanitize is a workaround for it to be compatible with IE. 
             * Source: https://stackoverflow.com/questions/56462877/popover-angular-bootstrap-4-3-failure
             */
            $('[data-toggle="tooltip"]').tooltip({
                sanitize: false, sanitizeFn: content => content
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>