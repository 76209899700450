import axios from 'axios'
import moment from 'moment'
import { auth, envelopes, bisnode } from "@verified/libvf";

export default {
  name: "app",
  data() {
    return {
			step: 1,
			lang: "sv_SE",
			loading: true,
			sent: false,
			config: null,
			banks: null,
			methods:null,
			prices : null,
			isElder:false,
			redirectUrl: "http://www.rawmotion.se/hem/",
			validation_error:true,
			ageValidate :'required|min:13',
			signingOrder: {
				signParallel : false,
				signSequential: true
			},
			ud: {
				source : null,
				_isForwarded: false	,
				fileName: null,
				translations: null,
				lang: "sv_SE",
				sourceTranslations: null,
				discount : null,
				otherPayer : {
					otherPayer : false
				},
				juniorTimePeriod: {
					radioOp1 : false,
					radioOp2 : false,
					radioOp3 : false,
					radioOp4 : false,
					radioOp5 : false,
					juniorRadOp1 : false,
					juniorRadOp2 : false,
					juniorRadOp3 : false,
					studentRadOp1 :false,
					studentRadOp2 : false,
					pensionerRadOp1 : false,
					pensionerRadOp2 : false
				},
				citizenShip: {
					swedish:false,
					others:false
				},
				isTaxPayeeInUSA:{
					yes:false,
					no:false
				},
				price:{
					large:true,
					small:false
				},
				investmentType:{
					childCare:false,
					saveTogether:false
				},
				childCareOption:{
					investmentAccount: false,
					traditionalEquity: false,
					portfolio: false
				},
				signatories : [{
					id: 1,
					ssn: null,
					firstName: null,
					lastName: null,
					email: null,
					method:null
				}]
			}
		}
	},
	methods: {
		getUrlParameter(param, dummyPath) {
			var sPageURL = dummyPath || window.location.search.substring(1),
				sURLVariables = decodeURIComponent(sPageURL).split(/[&||?]/),
				res;
			for (var i = 0; i < sURLVariables.length; i += 1) {
				var paramName = sURLVariables[i],
					sParameterName = (paramName || '').split('=');
				if (sParameterName[0] === param) {
					res = sParameterName[1];
				}
			}
			return res;
		},
		___(key){
			// console.log(key)
			// console.log(this.ud.sourceTranslations[key][this.ud.source])
			// alert(this.ud.source)
			if(this.ud.sourceTranslations[key][this.ud.source]){
				return this.ud.sourceTranslations[key][this.ud.source];
			}
			return '';
		},
		steps() {
			return [
				{ title: this.___('subTitle')},
				{  final:true }
			]
		},
		radioRule() {
			let retVal = ''
            if (Object.values(this.ud.juniorTimePeriod).filter(v => v==true).length === 0) {
                retVal = 'required'
            } 
			return retVal
        },
		agecheck(){
			var retVal = "required|min:13"
			if(this.ud.juniorTimePeriod.pensionerRadOp1||this.ud.juniorTimePeriod.pensionerRadOp2){
				retVal = retVal +'|age:65'
			}else{
				retVal = "required|min:13"
			}
			return retVal;
		},
		nextStep() {
			if(!this.validateForm(`form${this.step}`) ) {
				return
			}

			if(this.step >= 2)
				return this.submit()
			
			this.step++

		},

		previousStep(n) {
			this.step = this.step-1;
		},
		validateForm(form) {
			return this.$refs[form].validate()
		},
		getClearingNoRule(){
			let rule = this.banks[this.ud.bank].clearPattern.replace(/\|/g, '#')
			return `required|clearing-number:${this.ud.bank}~${rule}`
		},
		getAccountNoRule(){
			let rule = this.banks[this.ud.bank].accPattern
			return `required|account-number:${this.ud.bank}~${rule}`
		},
		addSigner(){
			this.ud.signatories.push({
				id: this.ud.signatories.length + 1,
				ssn: null,
				firstName: null,
				lastName: null,
				email: null,
				method:null
			})
		},
		removeLastSigner(){
			this.ud.signatories.pop();
		},

		async submit() {
			if(!this.validateForm('form2')){
				return
			}
			this.loading = true
			let selectedPrice =null;
			if(this.ud.juniorTimePeriod.studentRadOp1){
				selectedPrice = this.prices.studentRadOp1.price
			}else if(this.ud.juniorTimePeriod.studentRadOp2){
				selectedPrice = this.prices.studentRadOp2.price
			}else if(this.ud.juniorTimePeriod.pensionerRadOp1){
				selectedPrice = this.prices.studentRadOp1.price
			}else if(this.ud.juniorTimePeriod.pensionerRadOp2){
				selectedPrice = this.prices.studentRadOp2.price
			}
			if(selectedPrice!=null){
				this.ud.discount = parseInt(selectedPrice)*20/100;
			}else{
				this.ud.discount = 0;
			}
			console.log(this.ud.discount);
			let envelope = null
			let self = this
			self.fileName = "Raw Motion medlemsskap-"+self.ud.ssn;
			await envelopes.create('rawmotion-membership').then(function (res) {
				envelope = res;
			}).then(function () {
				return envelope.getBearerToken('/flows/simple-public-flow');
			}).then(function (token) {
				return auth.setToken(token);
			}).then(function (env) {
				return envelope.reflect();
			}).then(function(){
				envelope.firstTemplate().setUserData(self.ud)
			}).then(function () {
				let promises = []
				// self.ud.signatories.forEach(signatory => {
					// promises.push(
					return	envelope.addRecipient({
						"givenName": self.ud.firstName,
						"familyName": self.ud.lastName,
						"email": self.ud.email,
						"language": "sv_SE",
						"signingMethod": "bankid-se",
						"role": {
							"action": "sign",
							"label": "Public template signer",
							"name": "signer"
						}
					})
					// )
				// });
				// return Promise.all(promises)
			}).then(function () {
				return envelope.firstDocument().put({ name: self.fileName+'.pdf' });
			}).then(function () {
				return envelope.publish();
			// }).then(function () {
			// 	envelope.reflect();
			}).then(function () {
				self.sent = true
				// window.location.href = self.redirectUrl;
				envelope.getSignToken("/flows/simple-public-flow")
					.then(function (token) {
						self.sent = true
						// window.location.href = self.redirectUrl;
						var signUrl = "https://app.verified.eu/#/sign"+ envelope.data.uid + "?access_token=" + token + "&lang=" + self.ud.lang + "&redirect_to=" + encodeURIComponent(self.redirectUrl);
						// localStorage.setItem(envelope.data.id, $scope.signUrl);
						console.log("Got sign URL:", signUrl);
						window.location.href = signUrl;
					});
			}).catch(function (err) {
				console.log("ERROR", err);
				self.loading = false;
				throw err;
			});
			

		}
	},
	computed: {
		clearingNoMask(){
			return this.banks[this.ud.bank].clearMask
		},
		selectBanks(){
			if(this.banks){
				return Object.keys(this.banks)
			}
			return [];
		},
		radioValidate(){
			if(Object.values(this.ud.juniorTimePeriod).filter(v => v==true).length > 0){
				this.validation_error = false;
				return false;
			}else{
				return true;
			}
		}
	},	
	async created() {
		// Set language to use
		this.setIso(this.lang)

		const [translations, pdfTranslations, config, banks] = await Promise.all([
			axios.get('https://sheets.web-services.verified.eu/rawmotion-membership/translations'),
			axios.get('https://sheets.web-services.verified.eu/rawmotion-membership/pdf_translations'),
			axios.get('https://sheets.web-services.verified.eu/rawmotion-membership/config'),
			axios.get('https://sheets.web-services.verified.eu/rawmotion-membership/banks')
		])
		this.ud.source    = this.getUrlParameter('source')||'rawmotion';
		
		// This combines veform's default translations with the ones from sheets
		// duplicates are overwritten by the sheet
		this.prices = translations.data
		this.ud.sourceTranslations = translations.data
		this.mergeLocale(translations.data)
		this.ud.translations = pdfTranslations.data
		this.config = config.data
		this.banks = banks.data
		console.log("source =",this.ud.sourceTranslations.title[this.ud.source])
		let authOpts = {token:this.ud.sourceTranslations.token[this.ud.source], namespace:this.ud.sourceTranslations.namespace[this.ud.source]}
		auth.authenticate(authOpts)

		let clearingNoValidations = {"validation.clearingnumber":{}}
		let accountNoValidations = {"validation.accountnumber":{}}
		for (const key in this.banks) {
			if (this.banks.hasOwnProperty(key)) {
				const bank = this.banks[key];
				clearingNoValidations["validation.clearingnumber."+bank.text] = {}
				clearingNoValidations["validation.clearingnumber."+bank.text][this.lang] = bank.clearNoFormat
				accountNoValidations["validation.accountnumber."+bank.text]= {}
				accountNoValidations["validation.accountnumber."+bank.text][this.lang] = bank.accNoFormat
			}
		}
		this.mergeLocale(clearingNoValidations);
		this.mergeLocale(accountNoValidations);
		// It can be used multiple times
		this.mergeLocale({
			"validation.required": {
				en_EN: "Cannot be empty",
				nb_NO: "Påkrevd",
				sv_SE: "Obligatorisk"
			},"validation.elder": {
				en_EN: "Minimum age 65",
				nb_NO: "Minimumsalder 65",
				sv_SE: "Lägsta ålder 65"
			},"validation.min": {
				en_EN: "Minimum 12 digits",
				nb_NO: "Minimum 12 sifre",
				sv_SE: "Minst 12 siffror"
			},
			"validation.email":{
				en_EN: "Invalid email",
				nb_NO: "Ugyldig e-post",
				sv_SE: "Ogiltig e-post"		
			},
			"validation.radio": {
				en_EN: "Select one of the radio buttons",
				nb_NO: "Velg en av alternativknappene",
				sv_SE: "Välj en av alternativknapparna"
			},
			"validation.checkbox": {
				en_EN: "Select at least one of the checkboxes",
				nb_NO: "Välj en av alternativknapparna",
				sv_SE: "Välj en av alternativknapparna"
			},
			"ve-select.noresults": {
				en_EN: "No results matched \"%s\"",
				nb_NO: "Ingen resultater",
				sv_SE: "Inga resultat"
			},		
			"stepper.continue": {
				en_EN: "Continue",
				nb_NO: "Fortsett",
				sv_SE: "Fortsätt"
			},	
			"ve-select.empty": {
				en_EN: "Nothing selected",
				nb_NO: "Ingenting valgt",
				sv_SE: "Ingenting valt"
			},
			"ve-select.search": {
				en_EN: "Search",
				nb_NO: "Søk",
				sv_SE: "Sök"
			},
			"validation_error" : "Markera minst en av kryssrutorna"	
		})
		this.loading = false;
		let cover = document.getElementById("cover");
		if(this.config){
			cover.style.backgroundImage = `url(${this.config.background.value})`
		}
	}
}