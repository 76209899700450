import Vue from 'vue'
import App from './page/App.vue'

import veform from '@verified/veform'
import { validateUppercase} from './validations'
import { validateClearingNumber} from './validations'
import { validateAccountNumber} from './validations'
import { validateAge} from './validations'
import Stepper from './components/stepper'
import Checkbox from './components/checkbox'
import Radio from './components/radio'
import Label from './components/label'
Vue.config.productionTip = false

import "./page/style.scss"

veform.addCustomValidation('uppercase', validateUppercase)
veform.addCustomValidation('clearing-number', validateClearingNumber)
veform.addCustomValidation('account-number', validateAccountNumber)
veform.addCustomValidation('age', validateAge)

Vue.use(veform)
Vue.use(Stepper)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Label)

new Vue({
    render: h => h(App),
}).$mount('#app')